import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  LinearProgress, SelectChangeEvent,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { PageTitle } from "src/components/display/PageTitle";
import {
  OPERATION_TO_UPDATE_GSTINS,
  OPERATION_TO_ADD_ISP,
  ERROR_MESSAGE_FOR_DUPLICATE_ISP,
  API_PATH,
  ADD_OPERATION,
  UPDATE_OPERATION,
  LETTERS_AND_SINGLE_SPACE_REGEX,
  LABEL_FOR_ADD_ISP,
  LABEL_FOR_UPDATE_ISP,
} from "src/constants/ISPOnboarding";
import {
  addOrUpdateISPDetails,
  getISPDetails,
} from "../ISPAndSellerOnboardingAPI";
import { HomeState } from "src/views/HomeState";
import GSTIdsInput from "src/components/input/GSTIdsInput";

const ISPOnboarding = (props: any) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE;

  const baseUrl = useSelector(
    (state: CombineReducerState) => state.navbar.baseUrl
  );
  const ispDetails = useSelector(
    (state: { home: HomeState }) => state.home.ispDetails
  );

  const URL = baseUrl + API_PATH;

  const [operation, setOperation] = React.useState("");
  const [ispId, setISPId] = useState<string>("");
  const [ispName, setISPName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [gstinList, setGSTINList] = useState<Array<string>>([]);
  const [fetchedGSTINs, setFetchedGSTINs] = useState<Array<string>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [apiMessage, setApiMessage] = useState<string>("");
  const [isInvalidGST, setIsInvalidGST] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOperation((event.target as HTMLInputElement).value);
    clearForm();
  };

  useEffect(() => {
    if (!ispDetails[0].ispId)
      getISPDetails(URL, dispatch, setApiError, setApiErrorMessage);
  }, []);

  const handleChangeISP = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    let selectedISPName = e.target.value;
    selectedISPName = selectedISPName.replace(LETTERS_AND_SINGLE_SPACE_REGEX, "");
    setISPName(selectedISPName.trimLeft());
    const isDuplicateISP = ispDetails.find(
      (detail) => detail.ispName === selectedISPName.trim()
    );
    if (isDuplicateISP) {
      setApiErrorMessage(ERROR_MESSAGE_FOR_DUPLICATE_ISP + selectedISPName);
      setApiError(true);
    }
  };

  const handleSelectISP = (e: SelectChangeEvent) => {
    const selectedISPId = e.target.value;
    setISPId(selectedISPId);
    const selectedISP = ispDetails.find((detail: any) => detail.ispId === selectedISPId);
    if (selectedISP) {
      setFetchedGSTINs(selectedISP.gstins);
    } else {
      setFetchedGSTINs([]);
    }
  };

  const clearForm = () => {
    setGSTINList([]);
    setFetchedGSTINs([]);
    setISPName("");
    setISPId("");
    clearErrors();
  };

  const getPayload = () => {
    const isAddOperation = operation === ADD_OPERATION;
    if (isAddOperation) {
      return {
        operation: OPERATION_TO_ADD_ISP,
        ispName: ispName.trim(),
        gstins: [...new Set(gstinList)],
      };
    } else {
      return {
        operation: OPERATION_TO_UPDATE_GSTINS,
        ispId: ispId,
        gstins: [...new Set(gstinList)],
      };
    }
  };

  const handleAddOrUpdateISPDetails = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    clearErrors();
    await addOrUpdateISPDetails(
      URL,
      getPayload,
      dispatch,
      setOpen,
      setApiMessage,
      setApiError,
      setApiErrorMessage
    );
    
    clearForm();
    setLoading(false);
  };

  const isInputEmpty = () => {
    return !((ispId || ispName) && gstinList.length > 0);
  };

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
      <Grid container justifyContent="center">
        <PageTitle title={PAGE_TITLE} />
        <Grid container justifyContent="center">
          <Grid item sm={6}>
            <Paper elevation={4} sx={{ paddingY: 2 }}>
              <Box sx={{ paddingX: 3, paddingY: 1 }}>
                <FormControl size="small" fullWidth>
                  <FormLabel style={{ textAlign: "left" }}>
                    Choose an option
                  </FormLabel>
                  <RadioGroup
                      value={operation}
                      onChange={handleRadioChange}
                  >
                    <FormControlLabel
                        value={ADD_OPERATION}
                        control={<Radio />}
                        label={LABEL_FOR_ADD_ISP}
                        sx={{ width: "fit-content" }}
                    />
                    <FormControlLabel
                        value={UPDATE_OPERATION}
                        control={<Radio />}
                        label={LABEL_FOR_UPDATE_ISP}
                        sx={{ width: "fit-content" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item sm={6} sx={{ marginTop: 2 }}>
          <Paper elevation={4} sx={{ paddingY: 4 }}>
            <form onSubmit={handleAddOrUpdateISPDetails}>
              <Box sx={{ paddingX: 3, paddingY: 1 }}>
                {operation === ADD_OPERATION ? (
                    <FormControl size="small" fullWidth>
                      <TextField
                          id="ispName"
                          label="ISP Name"
                          multiline
                          fullWidth
                          value={ispName}
                          disabled={!operation}
                          onChange={handleChangeISP}
                      />
                    </FormControl>
                ) : (
                    <FormControl size="small" fullWidth>
                      <InputLabel id="id">ISP Name</InputLabel>
                      <Select
                          labelId="id"
                          label="ISP Name"
                          name="id"
                          sx={{ textAlign: "left" }}
                          value={ispId}
                          disabled={!operation}
                          onChange={handleSelectISP}
                      >
                        {ispDetails.map((detail: any) => (
                            <MenuItem key={detail.ispId} value={detail.ispId}>
                              {detail.ispName}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                )}
              </Box>
              {operation === UPDATE_OPERATION && (
                  <Box sx={{ paddingX: 3, paddingY: 1 }}>
                    <TextField
                        label="Fetched GSTINs"
                        value={fetchedGSTINs.join(", ")}
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                    />
                  </Box>
              )}
              <GSTIdsInput
                  label="GSTIN List"
                  inputId="gstINListId"
                  inputPlaceholder="Comma / Line separated GSTIN list"
                  rows="10"
                  value={gstinList}
                  handler={setGSTINList}
                  gstinList={gstinList}
                  handleInvalidGST={setIsInvalidGST}
              />
              <Box sx={{ paddingX: 3, paddingY: 1 }}>
                <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={isInputEmpty() || apiError || isInvalidGST}
                    fullWidth
                >
                  Submit
                </Button>
              </Box>
            </form>
            {loading && <LinearProgress />}
            {apiError && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={apiError}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                  <Alert severity="error" onClose={handleClose}>
                    {apiErrorMessage}
                  </Alert>
                </Snackbar>
            )}
            {open && !apiError && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    {apiMessage}
                  </Alert>
                </Snackbar>
            )}
          </Paper>
        </Grid>
      </Grid>
  );
};

export default ISPOnboarding;
