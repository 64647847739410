import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { PageTitle } from "src/components/display/PageTitle";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";

import {
  API_PATH,
  FAILED_TO_ONBOARD_MESSAGE,
  OOCFBA_SELLER_RESPONSE_INTERFACE,
  OPERATION_SAVE_SELLER_DETAILS,
  SUCCESSFULLY_ONBOARD_MESSAGE,
} from "src/constants/ISPOnboarding";
import { encryptMerchantId } from "src/helpers/MerchantIdsHelper";
import { SellerResponseTable } from "./SellerResponseTable";
import {
  getISPDetails,
  saveOOCFBASellerDetails,
} from "../ISPAndSellerOnboardingAPI";
import { HomeState } from "src/views/HomeState";

const OOCFBASellerOnboard = (props: any) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE;

  const baseUrl = useSelector(
    (state: CombineReducerState) => state.navbar.baseUrl
  );
  const URL_TO_SAVE_SELLER_DETAILS = baseUrl + API_PATH;
  const ispDetails = useSelector(
    (state: { home: HomeState }) => state.home.ispDetails
  );

  const [ispId, setISPId] = useState<String>();
  const [merchantIds, setMerchantIds] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sellerResponse, setSellerResponse] =
    useState<OOCFBA_SELLER_RESPONSE_INTERFACE>();
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [totalCount, setTotalCount] = useState<Number>(0);

  const dispatch = useDispatch();

  const isInputEmpty = () => !(ispId && merchantIds.length > 0);

  useEffect(() => {
    if (!ispDetails[0].ispId)
      getISPDetails(
        URL_TO_SAVE_SELLER_DETAILS,
        dispatch,
        setApiError,
        setApiErrorMessage
      );
  }, []);

  const handleSubmit = async () => {
    const encryptedMerchantIds: Array<string> = [...new Set(merchantIds)].map(
      (id: any) => {
        return isNaN(id) ? id : encryptMerchantId(id);
      }
    );
    setLoading(true);
    clearErrors();

    let payload = {
      operation: OPERATION_SAVE_SELLER_DETAILS,
      ispId: ispId,
      merchantIds: encryptedMerchantIds,
    };
    await saveOOCFBASellerDetails(
      URL_TO_SAVE_SELLER_DETAILS,
      payload,
      setTotalCount,
      setSellerResponse,
      setApiError,
      setApiErrorMessage
    );
    setLoading(false);
  };

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  return (
    <Grid container sx={{ padding: 0 }}>
      <PageTitle title={PAGE_TITLE} />
      <Grid item xs={4}>
        <Paper elevation={4} sx={{ paddingY: 2 }}>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="ISPId">ISP Name</InputLabel>
              <Select
                labelId="ISPId"
                label="ISP ID"
                name="ispId"
                sx={{ textAlign: "left" }}
                onChange={(e: SelectChangeEvent) => setISPId(e.target.value)}
              >
                {ispDetails.map((detail: any) => (
                  <MenuItem key={detail.ispId} value={detail.ispId}>
                    {detail.ispName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <MerchantIdsInput
            label="Merchant IDs"
            inputId="merchantId"
            inputPlaceholder="Comma / Line separated Merchant Ids"
            rows="18"
            value={merchantIds}
            handler={setMerchantIds}
          />
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={isInputEmpty()}
              onClick={handleSubmit}
              fullWidth
            >
              Submit
            </Button>
          </Box>
          {loading && <LinearProgress />}
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ paddingX: 3, pb: 2 }}>
          {sellerResponse && (
            <Paper elevation={4} sx={{ padding: "20px" }}>
              <Typography>Summary</Typography>
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ mb: 1 }}>
                  {SUCCESSFULLY_ONBOARD_MESSAGE}
                  {sellerResponse.successCount}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  {FAILED_TO_ONBOARD_MESSAGE} {totalCount}
                </Typography>
                <SellerResponseTable response={sellerResponse} />
              </Box>
            </Paper>
          )}
          {apiError && <Alert severity="error">{apiErrorMessage}</Alert>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OOCFBASellerOnboard;
