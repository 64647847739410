import { DEFAULT_ERROR_MESSAGE } from "src/constants/ISPOnboarding";
import apiRequest from "src/utils/ApiRequest";

export const fetchGSTReports = async (
  url: string,
  payload: any,
  setPresignedUrls: React.Dispatch<React.SetStateAction<any>>,
  setApiErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setApiError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await apiRequest(url, payload);
    const jsonData = await response.json();

    if (response.ok) {
      setApiError(false);
      const filteredData = jsonData.filter(
        (item: any) => Object.keys(item).length > 0
      );
      if (filteredData.length > 0) {
        setPresignedUrls(filteredData);
      } else {
        throw new Error(jsonData?.message ?? DEFAULT_ERROR_MESSAGE);
      }
    } else {
      throw new Error(jsonData.message ?? DEFAULT_ERROR_MESSAGE);
    }
  } catch (err: any) {
    setApiError(true);
    setApiErrorMessage(err.message);
  }
};
