import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import {
  OOCFBA_SELLER_RESPONSE_INTERFACE,
  SELLER_REASONS,
  SELLER_RESPONSE_TABLE_INTERFACE,
} from "src/constants/ISPOnboarding";

const columns: GridColDef[] = [
  { field: "id", headerName: "Seller ID", width: 250 },
  { field: "reason", headerName: "Reason", flex: 1 },
];

export const SellerResponseTable: React.FC<{
  response: OOCFBA_SELLER_RESPONSE_INTERFACE;
}> = ({ response }) => {
  const {
    failedSellerIds,
    existingSellerIds,
    sellersWithoutISPSubscription,
    sellerGSTINsNotInISP,
  } = response;

  const rows: SELLER_RESPONSE_TABLE_INTERFACE[] = [];

  failedSellerIds.forEach((sellerId) => {
    rows.push({ id: sellerId, reason: SELLER_REASONS.FAILED_TO_ONBOARD });
  });

  existingSellerIds.forEach((sellerId) => {
    rows.push({ id: sellerId, reason: SELLER_REASONS.ALREADY_EXIST });
  });

  sellersWithoutISPSubscription.forEach((sellerId) => {
    rows.push({
      id: sellerId,
      reason: SELLER_REASONS.WITHOUT_ISP_SUBSCRIPTION,
    });
  });

  Object.entries(sellerGSTINsNotInISP).forEach(([sellerId, gstins]) => {
    const reason =
      gstins.length === 0
        ? SELLER_REASONS.NO_GSTIN_FOUND
        : `${gstins.join(", ")} - ${SELLER_REASONS.NEW_GSTIN_FOUND}`;
    rows.push({ id: sellerId, reason });
  });

  return (
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        autoHeight
      />
  );
};

export default SellerResponseTable;