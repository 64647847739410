import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { GetApp } from "@mui/icons-material";
import { STATUS } from "src/constants/ISPOnboarding";

interface ROW_DATA {
  key: string;
  url: string;
  status: string
}

export const DataTable = ({ rows }: any) => {
  const [selectedReports, setSelectedReports] = useState<Array<string>>([]);
  const [pageSize, setPageSize] = useState(10);

  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadSelectedReports = () => {
    selectedReports.forEach((reportUrl: string, index: Number) => {
      setTimeout(() => {
        handleDownload(reportUrl);
      }, Number(index) * 1000);
    });
  };

  const handleSelectionModelChange = (selectedIds: GridSelectionModel) => {
    const selectedRows = rows.filter((row: ROW_DATA) =>
      selectedIds.includes(row.key) && row.status ===  STATUS.Success
    );
    const reports = selectedRows.map((row: ROW_DATA) => row.url);
    setSelectedReports(reports);
  };

  const columns: GridColDef[] = [
    {
      field: "url",
      headerName: "Reports",
      flex: 1,
      headerAlign: "center",
      renderHeader: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.colDef.headerName}</span>
      ),
      renderCell: (params) => {
        const filePath = params.row.key;
        return <span>{filePath.split("/").slice(2).join("/")}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.colDef.headerName}</span>
      ),
      renderCell: (params) => (
        <span
          style={
            params?.row?.status === STATUS.Failed
              ? { color: "#FFA500" }
              : { color: "#28A745" }
          }
        >
          {params?.row?.status}{" "}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.colDef.headerName}</span>
      ),
      renderCell: (params) => (
        <IconButton
          disabled={params?.row?.status === STATUS.Failed}
          onClick={(event) => {
            event.stopPropagation();
            handleDownload(params?.row?.url);
          }}
        >
          <GetApp />
        </IconButton>
      ),
    },
  ];
  return (
    <>
      {rows && (
        <>
          <Box display="flex" justifyContent="flex-end" sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedReports.length <= 0}
              onClick={downloadSelectedReports}
            >
              Download Selected Reports
            </Button>
          </Box>
          <DataGrid
            pagination
            rows={rows}
            columns={columns}
            getRowId={(row: any) =>  row?.key}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(pageSize:number)=> setPageSize(pageSize)}      
            onSelectionModelChange={handleSelectionModelChange}
            checkboxSelection
            autoHeight
          />
        </>
      )}
    </>
  );
};
